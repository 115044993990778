import React from "react"
import { Link } from "gatsby";

export default function error() {
  return(
    <div>
      <p>
        You done messed up A-A-ron, ain't nobody here by that name!
      </p>
      <Link to="/">Go Home</Link>
    </div>
  )
}